import { useSession } from 'next-auth/react';

import { PUBLICATION_INDEX_NAME, SORT_FIELDS } from '@/lib/constants/algolia';
import { getSortValue } from '@/lib/utils/aloglia';
import { RefinementList } from 'react-instantsearch';
import SavedList from '../publication-list/saved-list';
import CategoriesMenuList from './categoriesMenuList';
import ClearRefinements from './clearRefinements';
import CustomSortBy from './customSortBy';
import DomainAuthority from './domainAuthority';
import DomainRanking from './domainRanking';
import FromPresscartSection from './fromPresscartSection';
import Location from './location';
import PriceRange from './priceRange';
import TurnaroundTime from './turnaroundTime';

const Filters = () => {
  const { status } = useSession();

  return (
    <>
      <RefinementList attribute="id" className="hidden" />
      <div className="lg:w-[20rem] pr-8 space-y-10">
        {status === 'authenticated' && <SavedList />}
        <CustomSortBy
          items={[
            {
              label: 'Highest Domain Rating',
              value: getSortValue(PUBLICATION_INDEX_NAME),
            },
            {
              label: 'Highest Domain Authority',
              value: getSortValue(
                PUBLICATION_INDEX_NAME,
                SORT_FIELDS.DomainAuthority,
                'desc'
              ),
            },
            {
              label: 'Shortest turnaround time',
              value: getSortValue(
                PUBLICATION_INDEX_NAME,
                SORT_FIELDS.ShortestTurnAroundTime,
                'asc'
              ),
            },
            {
              label: 'Newest',
              value: getSortValue(
                PUBLICATION_INDEX_NAME,
                SORT_FIELDS.PublishedAt,
                'desc'
              ),
            },
            {
              label: 'Lowest Price',
              value: getSortValue(
                PUBLICATION_INDEX_NAME,
                SORT_FIELDS.Price,
                'asc'
              ),
            },
            {
              label: 'A - Z',
              value: getSortValue(
                PUBLICATION_INDEX_NAME,
                SORT_FIELDS.Name,
                'asc'
              ),
            },
            {
              label: 'Z - A',
              value: getSortValue(
                PUBLICATION_INDEX_NAME,
                SORT_FIELDS.Name,
                'desc'
              ),
            },
          ]}
        />
        <CategoriesMenuList
          attribute="publication_categories.name"
          sortBy={['name:asc']}
        />

        <Location />
        {status === 'authenticated' && <PriceRange />}
        <DomainAuthority />
        <DomainRanking />
        <TurnaroundTime />

        <FromPresscartSection />
        <ClearRefinements />
      </div>
    </>
  );
};

export default Filters;
