import ToggleRefinement from './toggleRefinement';
import FilterWrapper from '../filterWrapper';

const FromPresscartSection = () => {
  return (
    <FilterWrapper label="From Presscart" panelClassName={'space-y-3 mt-3'}>
      <div className="space-y-3">
        <ToggleRefinement label="Do follow" attribute="is_do_follow" />
        <ToggleRefinement
          label="Not sponsored"
          attribute="is_sponsored"
          on={['N', 'N (Disclaimer)']}
          // off={['Y', 'YN']}
        />
        <ToggleRefinement
          label="Google News"
          attribute="news"
          on={['Y', 'M']}

          // off={['N']}
        />
      </div>
    </FilterWrapper>
  );
};

export default FromPresscartSection;
