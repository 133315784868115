import AlertMessage from '@/components/alertMessage';
import { FormInput } from '@/components/form';
import { useCreateSaveList } from '@/lib/hooks/mutations/use-create-save-list';
import { usePublicationStore } from '@/lib/store/publication-list';
import { sleep } from '@/lib/utils/generalUtils';
import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon } from '@heroicons/react/solid';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MoonLoader } from 'react-spinners';
import { SAVED_LIST_QUERY_KEY } from './saved-list';

const CreatePublicationListModal = ({ isOpen, setIsOpen }) => {
  const methods = useForm();
  const { formState, handleSubmit, reset } = methods;

  const { selectedPublications, handleClearList } = usePublicationStore(
    (state) => state
  );

  const createList = useCreateSaveList();
  const queryClient = useQueryClient();

  const handleCreateList = async (payload) => {
    await sleep(2000);
    createList.mutateAsync(
      { name: payload?.name, publications: selectedPublications },
      {
        onError: () => toast.error('Failed to create a new list'),
        onSuccess: async () => {
          reset();

          queryClient.invalidateQueries({
            queryKey: [SAVED_LIST_QUERY_KEY],
          });

          await sleep(2000);
          return handleClearList();
        },
      }
    );
  };

  const isSubmitting = formState.isSubmitting || createList.isPending;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999]" onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-primary opacity-20 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-indigo-50 p-6 rounded-[40px] text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                {createList.isSuccess ? (
                  <AlertMessage
                    title="New List Created!"
                    status="success"
                    className="mb-0"
                    containerClassName="mb-0"
                  >
                    Your new list was added to Saved Lists
                  </AlertMessage>
                ) : (
                  <>
                    <h2 className="text-3xl leading-none tracking-tight mb-6">
                      Create a New List
                    </h2>
                    <FormProvider {...methods}>
                      <FormInput
                        name="name"
                        label="List Name"
                        placeholder="Enter List Name"
                        validation={{
                          required: 'This field is required',
                        }}
                      />
                      <button
                        className="button large w-full mt-5"
                        disabled={isSubmitting}
                        onClick={handleSubmit(handleCreateList)}
                      >
                        {isSubmitting ? (
                          <MoonLoader size={20} color={'#fff'} />
                        ) : (
                          <>
                            <PlusIcon className="icon-sm mr-2" />
                            Create New List
                          </>
                        )}
                      </button>
                    </FormProvider>
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreatePublicationListModal;
