import FilterWrapper from '../filterWrapper';
import TurnaroundTimeRangeSlider from './turnaroundTimeRangeSlider';
import TurnaroundTimeRangeInput from './turnaroundTimeRangeInput';

const TurnaroundTime = () => {
  return (
    <FilterWrapper label="Turnaround Time">
      <TurnaroundTimeRangeSlider />
      <TurnaroundTimeRangeInput />
    </FilterWrapper>
  );
};

export default TurnaroundTime;
