import Skeleton from '@/components/skeleton';
import { useInstantSearch } from 'react-instantsearch';

const LoadingIndicator = () => {
  const { status } = useInstantSearch();

  if (status === 'stalled') {
    return (
      <div className="space-y-2">
        {Array.from({ length: 5 }).map((_, idx) => (
          <Skeleton
            key={idx}
            className="w-full h-full aspect-square max-h-[400px] sm:max-h-[300px] lg:max-h-[256px] rounded-xl"
          />
        ))}
      </div>
    );
  }

  return null;
};

export default LoadingIndicator;
