import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, LocationMarkerIcon, SearchIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import React, { Fragment, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';

import FilterWrapper from '../filterWrapper';
import { cn } from '@/lib/utils/generalUtils';

const Location = () => {
  const { items, refine } = useRefinementList({ attribute: 'location', limit: 9999 });

  const [value, setValue] = useState(null);
  const [query, setQuery] = useState('');

  const selectedItems = items?.filter(item => item.isRefined);

  const filteredPeople =
    query === ''
      ? items
      : items?.filter(item =>
          item.value
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  if (items?.length === 0) return <></>;

  return (
    <FilterWrapper label="Location">
      <Combobox
        value={value}
        onChange={item => {
          setValue(value);
          refine(item.value);
        }}
      >
        <div className="relative">
          <div className="relative w-full cursor-default">
            <Combobox.Button className="w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 z-10">
                <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              </div>
              <Combobox.Input
                className={cn('w-full input !pl-9')}
                onChange={event => setQuery(event.target.value)}
                placeholder="Search for location"
              />
            </Combobox.Button>
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-out duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 z-30 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((item, idx) => (
                  <Combobox.Option
                    key={`${item.label}-${idx}`}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 px-4 ${
                        active ? 'bg-primary-75 text-primary-600' : 'text-gray-600'
                      }`
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <div className="flex items-center gap-x-2">
                        <LocationMarkerIcon className="text-gray-500 w-6 h-6" />
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                        >
                          {item.label}
                        </span>

                        {Boolean(selectedItems.find(selected => selected.value === item.value)) ? (
                          <span
                            className={`absolute inset-y-0 right-0 flex items-center pr-3 text-white`}
                          >
                            <CheckIcon className="h-5 w-5 text-primary-600" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>

          {selectedItems?.length > 0 && (
            <div className="mt-4 flex items-center gap-2 flex-wrap">
              {selectedItems?.map((selected, idx) => (
                <div
                  key={`${selected.label}-${idx}`}
                  className="text-primary-700 py-1 pl-3 pr-2 bg-primary-200 inline-flex gap-x-1 items-center font-bold rounded-full"
                >
                  <p>{selected.label}</p>
                  <button type="button" onClick={() => refine(selected.value)}>
                    <XIcon className="w-4 h-4 text-primary-400" />
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>
      </Combobox>
    </FilterWrapper>
  );
};

export default Location;
