import { usePublicationStore } from '@/lib/store/publication-list';
import { cn } from '@/lib/utils/generalUtils';
import { DuplicateIcon } from '@heroicons/react/outline';
import FiltersWithSearch from '../filters-with-search';
import SaveListMenu from './save-list-menu';

const PublicationListController = ({ hasListName, handleCopyClipboard }) => {
  const selectedPublications = usePublicationStore(
    (state) => state.selectedPublications
  );

  const isSelectedPublicationsEmpty = selectedPublications?.length === 0;

  return (
    <div
      className={cn(
        'flex items-center gap-x-2 justify-between py-2 px-3 bg-white/50 rounded-full',
        isSelectedPublicationsEmpty && 'bg-transparent p-0'
      )}
    >
      {hasListName ? (
        <button
          variant={'outline'}
          className={
            'text-gray-600 font-bold text-sm flex items-center gap-x-2 bg-transparent border-gray-600 border px-6 py-[22px] rounded-full hover:opacity-90'
          }
          onClick={handleCopyClipboard}
        >
          <DuplicateIcon className="w-5 h-5" />
          COPY LINK
        </button>
      ) : (
        <div className="flex items-center justify-between w-full">
          {isSelectedPublicationsEmpty ? (
            <FiltersWithSearch className="flex justify-between w-full flex-wrap gap-4 items-center" />
          ) : (
            // <div className="w-screen max-w-[190px] h-[70px] bg-transparent" />
            // <SaveListMenu />
            <SaveListMenu />
          )}
        </div>
      )}
    </div>
  );
};

export default PublicationListController;
