import React, { useEffect, useId, useMemo, useState } from 'react';
import { useClearRefinements, useConfigure, useRange } from 'react-instantsearch';

const unsetNumberInputValue = '';
function stripLeadingZeroFromInput(value) {
  return value.replace(/^(0+)\d/, part => Number(part).toString());
}

const TurnaroundTimeRangeInput = props => {
  const {
    start: minStart,
    range: minTurnaroundRange,
    canRefine: minCanRefine,
    precision,
  } = useRange({ attribute: 'min_turnaround_time_in_days', ...props });
  const {
    start: maxStart,
    range: maxTurnaroundRange,
    canRefine: maxCanRefine,
  } = useRange({ attribute: 'max_turnaround_time_in_days', ...props });

  const { refine } = useConfigure(props);
  const randomId = useId();

  const { refine: clearRefine } = useClearRefinements({
    includedAttributes: ['min_turnaround_time_in_days', 'max_turnaround_time_in_days'],
  });

  const step = 1 / Math.pow(10, precision || 0);

  const values = useMemo(() => {
    return {
      min:
        minStart[0] !== -Infinity && minStart[0] !== minTurnaroundRange.min
          ? minStart[0]
          : unsetNumberInputValue,
      max:
        maxStart[1] !== Infinity && maxStart[1] !== maxTurnaroundRange.max
          ? maxStart[1]
          : unsetNumberInputValue,
    };
  }, [maxStart, maxTurnaroundRange.max, minStart, minTurnaroundRange.min]);

  const [prevValues, setPrevValues] = useState(values);

  const [{ from, to }, setRange] = useState({
    from: values.min?.toString(),
    to: values.max?.toString(),
  });

  if (values.min !== prevValues.min || values.max !== prevValues.max) {
    setRange({ from: values.min?.toString(), to: values.max?.toString() });
    setPrevValues(values);
  }

  useEffect(() => {
    if (!from && !to) return clearRefine();
    if (!from || !to) return;

    let timeout;

    timeout = setTimeout(() => {
      refine({
        numericRefinements: {
          min_turnaround_time_in_days: {
            '>=': [from],
          },
          max_turnaround_time_in_days: {
            '<=': [to],
          },
        },
      });
    }, 1000);

    return () => clearTimeout(timeout);
  }, [clearRefine, from, refine, to]);

  return (
    <>
      <div className="flex items-center gap-x-2">
        <div className="space-y-2 flex-1">
          <label htmlFor={`minLabel-${randomId}`} className="text-sm font-bold text-gray-600">
            Min time (days)
          </label>
          <input
            id={`minLabel-${randomId}`}
            type="number"
            inputMode="numeric"
            min={minTurnaroundRange.min}
            max={minTurnaroundRange.max}
            value={stripLeadingZeroFromInput(from || unsetNumberInputValue)}
            step={step}
            placeholder={minTurnaroundRange.min?.toString()}
            disabled={!minCanRefine}
            className="input"
            onInput={({ currentTarget }) => {
              const value = currentTarget.value;

              setRange({ from: value || unsetNumberInputValue, to });
            }}
          />
        </div>
        <div className="space-y-2 flex-1">
          <label htmlFor={`maxLabel-${randomId}`} className="text-sm font-bold text-gray-600">
            Max time (days)
          </label>
          <input
            id={`maxLabel-${randomId}`}
            type="number"
            inputMode="numeric"
            min={maxTurnaroundRange.min}
            max={maxTurnaroundRange.max}
            value={stripLeadingZeroFromInput(to || unsetNumberInputValue)}
            step={step}
            placeholder={maxTurnaroundRange.max?.toString()}
            disabled={!maxCanRefine}
            className="input"
            onInput={({ currentTarget }) => {
              const value = currentTarget.value;

              setRange({ from, to: value || unsetNumberInputValue });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TurnaroundTimeRangeInput;
