import useModal from '@/lib/hooks/useModal';
import { cn } from '@/lib/utils/generalUtils';
import TalkToSalesModal from './talkToSalesModal';

const SupportCard = ({ siteId }) => {
  const inquiry = useModal();
  return (
    <>
      <div className="bg-white my-14 shadow-md p-8 rounded-[2.5rem] flex sm:flex-row flex-col sm:items-center">
        <div className="flex items-center  flex-1 ">
          <div className=" rounded-full bg-neutral-100 w-14 h-14 grid place-content-center">
            <QuestionIcon />
          </div>

          <div className="flex-1 sm:text-sm md:text-base ml-4">
            <p className="text-gray-700 font-bold">
              Can&apos;t find the outlet you&apos;re looking for?
            </p>
            <p className="text-gray-500">
              Submit a request, and we&apos;ll try to help.
            </p>
          </div>
        </div>

        <div className="flex items-center sm:ml-4 mt-6 sm:mt-0">
          <button
            type="button"
            className="uppercase text-sm flex w-full justify-center items-center gap-x-3 rounded-full border py-4 pr-6 pl-5 group font-bold hover:bg-primary-600 transition duration-150 ease-out"
            onClick={inquiry.open}
          >
            <EmailIcon className="text-primary-600 group-hover:text-white" />
            <p className="text-primary-600 group-hover:text-white">
              send a request
            </p>
          </button>
        </div>
      </div>

      <TalkToSalesModal
        site_id={siteId}
        isOpen={inquiry.isOpen}
        handleClose={inquiry.close}
      />
    </>
  );
};

export default SupportCard;

function QuestionIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 11.5C2 6.25329 6.25329 2 11.5 2C16.7467 2 21 6.25329 21 11.5C21 16.7467 16.7467 21 11.5 21C6.25329 21 2 16.7467 2 11.5ZM11.5 0C5.14872 0 0 5.14872 0 11.5C0 17.8513 5.14872 23 11.5 23C14.3752 23 17.004 21.9448 19.0202 20.2006C19.058 20.3862 19.1489 20.5631 19.2929 20.7071L24.2929 25.7071C24.6834 26.0976 25.3166 26.0976 25.7071 25.7071C26.0976 25.3166 26.0976 24.6834 25.7071 24.2929L20.7071 19.2929C20.5631 19.1489 20.3862 19.058 20.2006 19.0202C21.9448 17.004 23 14.3752 23 11.5C23 5.14872 17.8513 0 11.5 0ZM11.5 15.9844C12.0523 15.9844 12.5 16.4321 12.5 16.9844V16.9994C12.5 17.5517 12.0523 17.9994 11.5 17.9994C10.9477 17.9994 10.5 17.5517 10.5 16.9994V16.9844C10.5 16.4321 10.9477 15.9844 11.5 15.9844ZM10.3331 8.33625C10.5527 8.12526 10.8589 8 11.1862 8H11.8108C12.1381 8 12.4443 8.12526 12.6639 8.33625C12.8822 8.54593 12.997 8.82154 12.997 9.1C12.997 9.11686 12.9974 9.13371 12.9983 9.15054C13.0116 9.41491 12.9469 9.66896 12.8259 9.87253C12.6697 10.135 12.4829 10.3518 12.2634 10.5724C12.1677 10.6686 12.0723 10.7592 11.9672 10.8591L11.9671 10.8592L11.9085 10.9149C11.7848 11.0326 11.6476 11.1645 11.5146 11.3059C11.2442 11.5935 10.9645 11.9479 10.767 12.4158C10.5677 12.8883 10.4757 13.4197 10.4992 14.0379C10.5202 14.5898 10.9845 15.0202 11.5364 14.9993C12.0883 14.9783 12.5187 14.514 12.4978 13.9621C12.4838 13.5936 12.5396 13.3594 12.6097 13.1934C12.6816 13.0229 12.7929 12.8661 12.9717 12.6759C13.0633 12.5785 13.1652 12.4799 13.2872 12.3637L13.3406 12.3131L13.3406 12.313C13.4453 12.2136 13.5639 12.101 13.6812 11.983C13.9636 11.6992 14.2733 11.3513 14.5447 10.895C14.87 10.3482 15.0243 9.71074 14.9969 9.07443C14.9899 8.24839 14.645 7.46597 14.0494 6.8939C13.4491 6.31723 12.6431 6 11.8108 6H11.1862C10.3538 6 9.5479 6.31723 8.94757 6.8939C8.34587 7.47187 8 8.26455 8 9.1C8 9.65228 8.44771 10.1 9 10.1C9.55228 10.1 10 9.65228 10 9.1C10 8.82154 10.1148 8.54593 10.3331 8.33625Z"
        fill="#2C2C2C"
      />
    </svg>
  );
}

function EmailIcon({ className }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={cn(className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.11219 6.53954C4.27877 6.21897 4.61387 6 5.00011 6H19.0001C19.3863 6 19.7214 6.21897 19.888 6.53954L12.0001 11.7981L4.11219 6.53954ZM2.00017 6.98083C1.99997 6.99179 1.99995 7.00273 2.00011 7.01367V17C2.00011 18.6569 3.34325 20 5.00011 20H19.0001C20.657 20 22.0001 18.6569 22.0001 17V7.01368C22.0003 7.00274 22.0002 6.99178 22 6.98083C21.9897 5.3328 20.6506 4 19.0001 4H5.00011C3.34965 4 2.01048 5.3328 2.00017 6.98083ZM20.0001 8.86852V17C20.0001 17.5523 19.5524 18 19.0001 18H5.00011C4.44782 18 4.00011 17.5523 4.00011 17V8.86852L11.4454 13.8321C11.7813 14.056 12.2189 14.056 12.5548 13.8321L20.0001 8.86852Z"
        fill="currentColor"
      />
    </svg>
  );
}
