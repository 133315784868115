import { useEffect, useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';

const ShowingTotalResults = () => {
  const { results } = useInstantSearch();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null;

  return (
    <p className="mb-4">
      Showing {results?.hits?.length ?? 0} of{' '}
      <span className="font-bold">{results?.nbHits ?? 0} results</span>
    </p>
  );
};

export default ShowingTotalResults;
