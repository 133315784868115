import { useEffect, useState } from 'react';
import { Slider } from '../../ui/slider';
import { useRange } from 'react-instantsearch';

const RangeSlider = props => {
  const { start, range, canRefine, refine } = useRange(props);
  const { min, max } = range;
  const [value, setValue] = useState([min, max]);

  const from = Math.max(min, Number.isFinite(start[0]) ? start[0] : min);
  const to = Math.min(max, Number.isFinite(start[1]) ? start[1] : max);

  useEffect(() => {
    let timeout;

    timeout = setTimeout(() => setValue([from, to]), 1000);

    return () => clearTimeout(timeout);
  }, [from, to]);

  return (
    <Slider
      min={min}
      max={max}
      value={value}
      onValueChange={setValue}
      onValueCommit={refine}
      disabled={!canRefine}
    />
  );
};

export default RangeSlider;
