import { useQuery } from '@tanstack/react-query';

import API from '@/lib/api';
import PublicationCardHit from './publicationCardHit';
import { cn } from '@/lib/utils/generalUtils';

const DealsPublicationsList = ({
  page = 1,
  siteId,
  isWhitelabelOwner,
  handlePublicationInquiryOpen,
  handleRequirementsOpen,
  showBasePrices,
}) => {
  const { data } = useQuery({
    queryKey: ['deals', page],
    queryFn: async () => {
      const res = await API.deals.find({ pageNumber: page, site_id: siteId, showBasePrices });

      return res.data;
    },
  });

  const deals = siteId
    ? data
    : data?.reduce((total, currentItem) => {
        if (!total.some(obj => obj.id === currentItem.id)) {
          total.push(currentItem);
        }
        return total;
      }, []);

  return (
    <div className={cn(deals?.length > 0 && 'mb-16')}>
      {deals?.length > 0 && (
        <>
          <h2 className="text-left text-[2.5rem] leading-none mb-10 text-primary-900">Deals</h2>
          <div className="space-y-2">
            {deals.map((publication, index) => (
              <PublicationCardHit
                key={`deal-${index}`}
                data={publication}
                handlePublicationInquiryOpen={handlePublicationInquiryOpen}
                handleRequirementsOpen={handleRequirementsOpen}
                isWhitelabelOwner={isWhitelabelOwner}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default DealsPublicationsList;
