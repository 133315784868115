import { useSortBy } from 'react-instantsearch';

import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { cn } from '@/lib/utils/generalUtils';
import FilterWrapper from '../filterWrapper';

const CustomSortBy = props => {
  const { currentRefinement, options, refine } = useSortBy(props);

  return (
    <FilterWrapper label="Sort" panelClassName="mt-4 space-y-4">
      <RadioGroup value={currentRefinement} onValueChange={value => refine(value)}>
        {options.map((option, idx) => (
          <div key={idx} className="flex items-center space-x-2">
            <RadioGroupItem value={option.value} id={option.value} />
            <label
              htmlFor={option.value}
              className={cn(
                'font-bold text-gray-500',
                option.value === currentRefinement && 'text-gray-600'
              )}
            >
              {option.label}
            </label>
          </div>
        ))}
      </RadioGroup>
    </FilterWrapper>
  );
};

export default CustomSortBy;
