import { Slider } from '@/components/ui/slider';
import { PUBLICATION_INDEX_NAME } from '@/lib/constants/algolia';
import { useEffect, useState } from 'react';
import { useConfigure, useRange } from 'react-instantsearch';

const TurnaroundTimeRangeSlider = props => {
  const {
    start: minStart,
    range: minRange,
    canRefine: minCanRefine,
  } = useRange({
    attribute: 'min_turnaround_time_in_days',
    ...props,
  });
  const {
    start: maxStart,
    range: maxRange,
    canRefine: maxCanRefine,
  } = useRange({
    attribute: 'max_turnaround_time_in_days',
    ...props,
  });

  const { refine } = useConfigure(props);

  const { min } = minRange;
  const { max } = maxRange;
  const [value, setValue] = useState([min, max]);

  const from = Math.max(min, Number.isFinite(minStart[0]) ? minStart[0] : min);
  const to = Math.min(max, Number.isFinite(maxStart[1]) ? maxStart[1] : max);

  useEffect(() => {
    setValue([from, to]);
  }, [from, to]);

  return (
    <Slider
      min={min}
      max={max}
      value={value}
      onValueChange={setValue}
      onValueCommit={value => {
        const [min, max] = value;
        refine({
          numericRefinements: {
            min_turnaround_time_in_days: {
              '>=': [min],
            },
            max_turnaround_time_in_days: {
              '<=': [max],
            },
          },
        });
      }}
      disabled={!minCanRefine && !maxCanRefine}
    />
  );
};

export default TurnaroundTimeRangeSlider;
