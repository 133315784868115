import { cn } from '@/lib/utils/generalUtils';
import { CheckIcon, ClockIcon } from '@heroicons/react/outline';
import { TrendingUpIcon } from '@heroicons/react/solid';

const Badge = ({ children, variant, className }) => {
  return (
    <p
      className={cn(
        'uppercase text-xs inline-flex items-center font-bold bg-gray-100 text-gray-600 rounded-2xl px-2 py-1 mx-1',
        variant === 'success' && 'bg-success-50 text-success-700',
        variant === 'popular' && 'text-secondary-600 bg-secondary',
        variant === 'pending' && 'text-error-700 bg-error-50',
        className
      )}
    >
      {variant === 'success' && (
        <CheckIcon className="w-3 h-3 text-green-400 mr-1" />
      )}
      {variant === 'popular' && (
        <TrendingUpIcon className="w-3 h-3 text-secondary-600 mr-1" />
      )}
      {variant === 'pending' && <ClockIcon className="w-3 h-3  mr-1" />}

      {children}
    </p>
  );
};

export default Badge;
