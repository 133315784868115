import { useEffect, useId, useState } from 'react';
import { useRange } from 'react-instantsearch';

function stripLeadingZeroFromInput(value) {
  return value.replace(/^(0+)\d/, part => Number(part).toString());
}

const unsetNumberInputValue = '';

const RangeInput = props => {
  const { start, range, canRefine, precision, refine } = useRange(props);

  const step = 1 / Math.pow(10, precision || 0);

  const values = {
    min: start[0] !== -Infinity && start[0] !== range.min ? start[0] : unsetNumberInputValue,
    max: start[1] !== Infinity && start[1] !== range.max ? start[1] : unsetNumberInputValue,
  };

  const randomId = useId();
  const [prevValues, setPrevValues] = useState(values);

  const [{ from, to }, setRange] = useState({
    from: values.min?.toString(),
    to: values.max?.toString(),
  });

  if (values.min !== prevValues.min || values.max !== prevValues.max) {
    setRange({ from: values.min?.toString(), to: values.max?.toString() });
    setPrevValues(values);
  }

  useEffect(() => {
    if (!from && !to) return;

    let timeout;

    timeout = setTimeout(() => {
      refine([from ? Number(from) : undefined, to ? Number(to) : undefined]);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [from, refine, to]);

  return (
    <>
      <div className="flex items-center gap-x-2">
        <div className="space-y-2 flex-1">
          <label htmlFor={`minLabel-${randomId}`} className="text-sm font-bold text-gray-600">
            {props.minLabel}
          </label>
          <input
            id={`minLabel-${randomId}`}
            type="number"
            inputMode="numeric"
            min={range.min}
            max={range.max}
            value={stripLeadingZeroFromInput(from || unsetNumberInputValue)}
            step={step}
            placeholder={range.min?.toString()}
            disabled={!canRefine}
            className="input"
            onInput={({ currentTarget }) => {
              const value = currentTarget.value;

              setRange({ from: value || unsetNumberInputValue, to });
            }}
          />
        </div>
        <div className="space-y-2 flex-1">
          <label htmlFor={`maxLabel-${randomId}`} className="text-sm font-bold text-gray-600">
            {props.maxLabel}
          </label>
          <input
            id={`maxLabel-${randomId}`}
            type="number"
            inputMode="numeric"
            min={range.min}
            max={range.max}
            value={stripLeadingZeroFromInput(to || unsetNumberInputValue)}
            step={step}
            placeholder={range.max?.toString()}
            disabled={!canRefine}
            className="input"
            onInput={({ currentTarget }) => {
              const value = currentTarget.value;

              setRange({ from, to: value || unsetNumberInputValue });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RangeInput;
