import RangeSlider from './rangeSlider';
import RangeInput from './rangeInput';
import FilterWrapper from '../filterWrapper';

const DomainAuthority = () => {
  return (
    <FilterWrapper label="Domain Authority">
      <RangeSlider attribute="domain_authority" />
      <RangeInput attribute="domain_authority" minLabel="Min value" maxLabel="Max value" />
    </FilterWrapper>
  );
};

export default DomainAuthority;
