import Badge from '../badge';
import Tooltip from '../tooltip';

const PublicationCategories = ({ categories }) => {
  const topThreeCategories = categories?.slice(0, 5);
  const theRestCategories = categories?.slice(5);

  return (
    <div className="flex flex-wrap items-start gap-1 w-full max-w-[300px] lg:max-w-[unset]">
      {topThreeCategories?.map(category => (
        <Badge key={category.id}>{category.name || category.attributes.name}</Badge>
      ))}

      {theRestCategories?.length > 0 && (
        <Tooltip
          content={
            <div className="flex items-center gap-x-1 gap-y-3 flex-wrap">
              {theRestCategories?.map(category => (
                <Badge key={category.id}>{category.name || category.attributes.name}</Badge>
              ))}
            </div>
          }
        >
          <Badge>+{theRestCategories.length}</Badge>
        </Tooltip>
      )}
    </div>
  );
};

export default PublicationCategories;
