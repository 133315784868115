import classNames from 'classnames';
import React from 'react';

const Skeleton = ({ className, ...props }) => {
  return (
    <div className={classNames('animate-pulse rounded-md bg-gray-200', className)} {...props} />
  );
};

export default Skeleton;
