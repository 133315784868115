import RangeSlider from './rangeSlider';
import RangeInput from './rangeInput';
import FilterWrapper from '../filterWrapper';

const DomainRanking = () => {
  return (
    <FilterWrapper label="Domain Ranking">
      <RangeSlider attribute="domain_ranking" />
      <RangeInput attribute="domain_ranking" minLabel="Min value" maxLabel="Max value" />
    </FilterWrapper>
  );
};

export default DomainRanking;
