import classNames from 'classnames';
import { startCase } from 'lodash';
import { useClearRefinements, useMenu } from 'react-instantsearch';
import FilterWrapper from '../filterWrapper';

const transformItems = (items) => {
  const defaultCategory = {
    label: 'All Categories',
    value: 'All',
    count: 0,
    isRefined: items.every((item) => !item.isRefined),
  };

  return [defaultCategory].concat(items);
};

const NUMERIC_FILTERS = [
  'price',
  'domain_ranking',
  'domain_authority',
  'min_turnaround_time_in_days',
  'max_turnaround_time_in_days',
];

const CategoriesMenuList = (props) => {
  const { items, refine } = useMenu({ ...props, limit: 9999, transformItems });
  const { refine: clearRefine } = useClearRefinements({
    includedAttributes: NUMERIC_FILTERS,
  });
  const totalCountItems = items.reduce((total, item) => item.count + total, 0);

  return (
    <FilterWrapper label="Categories" panelClassName={'space-y-4 mt-4'}>
      <ul className="flex items-center gap-2 flex-wrap">
        {items.map((item) => (
          <li key={item.label}>
            <button
              onClick={(event) => {
                event.preventDefault();

                if (item.value === 'All') return refine('');

                clearRefine();

                refine(item.value);
              }}
              className={classNames(
                'px-4 py-2 rounded-full font-bold block',
                item.isRefined
                  ? 'bg-neutral-300 text-primary-700'
                  : 'text-gray-600 bg-gray-50 lg:bg-white/50'
              )}
            >
              <span>{startCase(item.label)}</span>
              {/* <span
                className={classNames(
                  'inline-block ml-2 font-bold px-2 py-1 rounded-full text-xs',
                  item.isRefined ? 'bg-primary-300 text-primary-700' : 'bg-gray-200 text-gray-600'
                )}
              >
                {item.value === 'All' ? totalCountItems : item.count}
              </span> */}
            </button>
          </li>
        ))}
      </ul>
    </FilterWrapper>
  );
};

export default CategoriesMenuList;
