import { cn } from '@/lib/utils/generalUtils';
import { Switch } from '@headlessui/react';
import { useToggleRefinement } from 'react-instantsearch';

const ToggleRefinement = props => {
  const { value, refine } = useToggleRefinement(props);

  return (
    <label className="flex items-center">
      <div className="inline-flex items-center flex-1">
        <span className={cn('font-bold', value.isRefined ? 'text-gray-600' : 'text-gray-500')}>
          {props.label}
        </span>
      </div>

      <Switch
        checked={value.isRefined}
        onChange={checked => {
          refine({ isRefined: !checked });
        }}
        className={`${value.isRefined ? 'bg-primary-600' : 'bg-gray-300'}
      relative inline-flex h-5 w-9 shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
      >
        <span
          aria-hidden="true"
          className={`${value.isRefined ? 'translate-x-4' : 'translate-x-0'}
        pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </label>
  );
};

export default ToggleRefinement;
