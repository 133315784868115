import { useQuery } from '@tanstack/react-query';
import api from '../api';

const usePublicationList = (id) => {
  return useQuery({
    queryKey: ['lists', { id }],
    queryFn: () =>
      api.apiClient.get(`/publication-lists/${id}`).then((res) => res.data),
    enabled: !!id,
  });
};

export default usePublicationList;
