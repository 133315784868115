import { cn } from '@/lib/utils/generalUtils';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { usePagination } from 'react-instantsearch';

const CustomPagination = (props) => {
  const { pages, currentRefinement, nbPages, isFirstPage, isLastPage, refine } =
    usePagination(props);
  const firstPageIndex = 0;
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const lastPageIndex = nbPages - 1;

  const router = useRouter();

  const btnClass =
    'bg-white rounded-full text-gray-600 p-3  disabled:text-gray-400';

  if (nbPages <= 1) return null;

  return (
    <ul className="flex items-center gap-x-3 mt-4 pb-10">
      <PaginationItem
        isDisabled={isFirstPage}
        onClick={() => {
          refine(firstPageIndex);
          router.push('#press-lists');
        }}
        className={btnClass}
      >
        <ChevronDoubleLeftIcon className="h-4 w-4" />
        <div className="sr-only">First</div>
      </PaginationItem>

      <PaginationItem
        isDisabled={isFirstPage}
        onClick={() => {
          refine(previousPageIndex);
          router.push('#press-lists');
        }}
        className={btnClass}
      >
        <ArrowLeftIcon className="h-4 w-4" />
        <div className="sr-only">Previous</div>
      </PaginationItem>

      <div role="list" className="flex items-center justify-center flex-1">
        {pages.map((page) => {
          const label = page + 1;

          return (
            <PaginationItem
              key={page}
              isDisabled={false}
              aria-label={`Page ${label}`}
              // href={createURL(page)}
              className={cn(
                'sm:flex h-11 w-11 hidden cursor-pointer sm:items-center sm:justify-center rounded-full text-sm',
                page === currentRefinement
                  ? 'bg-primary-100 text-primary-700 font-bold'
                  : 'text-gray-500'
              )}
              onClick={() => {
                refine(page);
                router.push('#press-lists');
              }}
            >
              {label}
            </PaginationItem>
          );
        })}
      </div>

      <PaginationItem
        isDisabled={isLastPage}
        onClick={() => {
          refine(nextPageIndex);
          router.push('#press-lists');
        }}
        className={btnClass}
      >
        <ArrowRightIcon className="h-4 w-4" />
        <div className="sr-only">Next</div>
      </PaginationItem>

      <PaginationItem
        isDisabled={isLastPage}
        onClick={() => {
          refine(lastPageIndex);
          router.push('#press-lists');
        }}
        className={btnClass}
      >
        <ChevronDoubleRightIcon className="h-4 w-4" />
        <div className="sr-only">Last</div>
      </PaginationItem>
    </ul>
  );
};

export default CustomPagination;

function PaginationItem({ isDisabled, href, onClick, ...props }) {
  if (isDisabled) {
    return (
      <li>
        <button {...props} disabled />
      </li>
    );
  }

  return (
    <li>
      <button
        onClick={(event) => {
          if (isModifierClick(event)) {
            return;
          }

          event.preventDefault();

          onClick(event);
        }}
        {...props}
      />
    </li>
  );
}

function isModifierClick(event) {
  const isMiddleClick = event.button === 1;

  return Boolean(
    isMiddleClick ||
      event.altKey ||
      event.ctrlKey ||
      event.metaKey ||
      event.shiftKey
  );
}
