import { useSession } from 'next-auth/react';
import { useState } from 'react';

const usePublicationHandler = () => {
  const [isDetailOpen, setDetailOpen] = useState(false);
  const [highlightedPublication, setHighlightedPublication] = useState(null);
  const [isInquiryOpen, setInquiryOpen] = useState(false);

  const { data: session } = useSession(); // Replace with your actual session hook

  const handlePublicationDetailsOpen = publication => {
    if (!session) {
      setOpenAccessPricingModal(true);
      return;
    }

    setDetailOpen(true);
    setInquiryOpen(false);
    setHighlightedPublication(publication);
  };

  const handlePublicationInquiryOpen = publication => {
    if (!session) {
      setOpenAccessPricingModal(true);
      return;
    }
    setDetailOpen(false);
    setInquiryOpen(true);
    setHighlightedPublication(publication);
  };

  const handleCloseInquiryModal = () => {
    setInquiryOpen(false);
    setHighlightedPublication(null);
  };

  return {
    isDetailOpen,
    setDetailOpen,
    isInquiryOpen,
    setInquiryOpen,
    highlightedPublication,
    handlePublicationDetailsOpen,
    handlePublicationInquiryOpen,
    handleCloseInquiryModal,
  };
};

export default usePublicationHandler;
