const Search = (props) => {
  return (
    <svg viewBox="0 0 21 20" fill="none" {...props}>
      <g clipPath="url(#clip0_6737_35860)">
        <path
          d="M8.66927 14.1667C11.8909 14.1667 14.5026 11.555 14.5026 8.33333C14.5026 5.11167 11.8909 2.5 8.66927 2.5C5.44761 2.5 2.83594 5.11167 2.83594 8.33333C2.83594 11.555 5.44761 14.1667 8.66927 14.1667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.8359 17.5L12.8359 12.5"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6737_35860">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.335938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Search;
