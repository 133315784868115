import { useInstantSearch } from 'react-instantsearch';

const Error = () => {
  const { error, setUiState } = useInstantSearch({ catchError: true });

  if (error) {
    return (
      <>
        <p className="text-red-500 text-sm">Search error: {error.message}</p>
        <button
          className="text-primary-600 mt-4 font-bold"
          onClick={() => {
            setUiState({});
          }}
        >
          Reset search
        </button>
      </>
    );
  }

  return null;
};

export default Error;
