import { FilterIcon } from '@heroicons/react/outline';
import { Sheet, SheetContent, SheetTrigger } from '../ui/sheet';
import Filters from './widgets/filters';

const FiltersDialog = () => {
  return (
    <div className="text-right lg:hidden">
      <Sheet modal={false}>
        <SheetTrigger asChild>
          <button
            type="button"
            className="inline-flex items-center text-gray-700 font-semibold"
          >
            <FilterIcon className="w-5 h-5 mr-1" />
            Filter
          </button>
        </SheetTrigger>
        <SheetContent className="overflow-y-auto">
          <Filters />
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default FiltersDialog;
