import { cn } from '@/lib/utils/generalUtils';

const FilterWrapper = ({ children, label, panelClassName }) => {
  return (
    <div>
      <p className="filter-heading">{label}</p>
      <div className={cn('text-gray-500 mt-6 space-y-6', panelClassName)}>{children}</div>
    </div>
  );
};

export default FilterWrapper;
