const Press = (props) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 3.50012C5.21449 3.50012 4.621 3.74596 4.18342 4.18354C3.74583 4.62112 3.5 5.21462 3.5 5.83346V19.8335C3.5 21.0711 3.99167 22.2581 4.86684 23.1333C5.74201 24.0085 6.92899 24.5001 8.16667 24.5001H21C21.9283 24.5001 22.8185 24.1314 23.4749 23.475C24.1312 22.8186 24.5 21.9284 24.5 21.0001V8.16679C24.5 7.54795 24.2542 6.95446 23.8166 6.51687C23.379 6.07929 22.7855 5.83346 22.1667 5.83346L19.8333 5.83346C19.8333 5.21462 19.5875 4.62112 19.1499 4.18354C18.7123 3.74596 18.1188 3.50012 17.5 3.50012H5.83333ZM8.16667 22.1668H17.7002C17.5689 21.7955 17.5 21.4014 17.5 21.0001V7.00012L17.5 5.83346L5.83333 5.83346L5.83333 19.8335C5.83333 20.4523 6.07917 21.0458 6.51675 21.4834C6.95434 21.921 7.54783 22.1668 8.16667 22.1668ZM19.8333 21.0001V8.16679H22.1667V21.0001C22.1667 21.3095 22.0437 21.6063 21.825 21.8251C21.6062 22.0439 21.3094 22.1668 21 22.1668C20.6906 22.1668 20.3938 22.0439 20.175 21.8251C19.9562 21.6063 19.8333 21.3095 19.8333 21.0001ZM9.33333 8.16679C8.689 8.16679 8.16667 8.68912 8.16667 9.33346C8.16667 9.97779 8.689 10.5001 9.33333 10.5001H14C14.6443 10.5001 15.1667 9.97779 15.1667 9.33346C15.1667 8.68912 14.6443 8.16679 14 8.16679H9.33333ZM8.16667 14.0001C8.16667 13.3558 8.689 12.8335 9.33333 12.8335H14C14.6443 12.8335 15.1667 13.3558 15.1667 14.0001C15.1667 14.6445 14.6443 15.1668 14 15.1668H9.33333C8.689 15.1668 8.16667 14.6445 8.16667 14.0001ZM9.33333 17.5001C8.689 17.5001 8.16667 18.0225 8.16667 18.6668C8.16667 19.3111 8.689 19.8335 9.33333 19.8335H14C14.6443 19.8335 15.1667 19.3111 15.1667 18.6668C15.1667 18.0225 14.6443 17.5001 14 17.5001H9.33333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Press;
