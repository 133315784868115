import { cn } from '@/lib/utils/generalUtils';
import { useRef, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';

let timerId = undefined;
let timeout = 700;

function queryHook(query, search) {
  if (timerId) {
    clearTimeout(timerId);
  }

  timerId = setTimeout(() => search(query), timeout);
}

const Search = props => {
  const { query, refine } = useSearchBox({ queryHook });
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef(null);

  function setQuery(newQuery) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <div className={cn(props.className)}>
      <form
        role="search"
        noValidate
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();

          if (inputRef.current) {
            inputRef.current.blur();
          }
        }}
        onReset={event => {
          event.preventDefault();
          event.stopPropagation();

          setQuery('');

          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}
      >
        <input
          ref={inputRef}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="Search"
          spellCheck={false}
          maxLength={512}
          type="search"
          value={inputValue}
          onChange={event => {
            setQuery(event.currentTarget.value);
          }}
          className={cn('input')}
        />
      </form>
    </div>
  );
};

export default Search;
