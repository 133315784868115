import { useQuery } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import api from '../api';

export const SAVED_LIST_QUERY_KEY = 'saved_list';

export const useSavedList = () => {
  const { data: session } = useSession();

  const profileId = session?.profile?.id;

  return useQuery({
    queryKey: [SAVED_LIST_QUERY_KEY, { profileId }],
    queryFn: () =>
      api.publicationLists
        .getPublicationLists(profileId)
        .then((res) => res.data),
    enabled: !!profileId,
  });
};
