import { produce } from 'immer';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const usePublicationStore = create()(
  persist(
    (set) => ({
      /* States */

      isOpen: false,
      action: null,
      publication: null,
      selectedPublications: [],
      selectedSavedList: null,
      callback: () => {},

      /* Functions */

      handleOpen: (open, action, publication, callback) =>
        set((state) => ({
          isOpen: open,
          action,
          publication,
          callback: callback ?? state.callback,
        })),

      handleAddToList: (selectedPublicationId) => {
        set(
          produce((state) => {
            state.selectedPublications.push(selectedPublicationId);
          })
        );
      },

      handleRemoveToList: (selectedPublicationId) => {
        set((state) => ({
          selectedPublications: state.selectedPublications.filter(
            (existingPubId) => existingPubId !== selectedPublicationId
          ),
        }));
      },

      handleClearList: () => {
        set((state) => ({ selectedPublications: [] }));
      },

      handleSelectedSavedList: (list) => {
        set(() => ({ selectedSavedList: list }));
      },

      handleClearSelectedSavedList: () =>
        set(() => ({ selectedSavedList: null })),

      setIsOpen: (open) => set(() => ({ isOpen: open })),
    }),
    {
      name: 'saved_publication_list',
      partialize: (state) => ({
        selectedPublications: state.selectedPublications,
      }),
    }
  )
);
