import { cn } from '@/lib/utils/generalUtils';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/outline';
export default function AlertMessage({
  title,
  status,
  children,
  containerClassName,
}) {
  return (
    <div
      className={cn(
        'border px-4 py-5 rounded-lg sm:px-6 mb-4',
        status == 'alert' && 'bg-amber-50 border-amber-500',
        status == 'error' && 'bg-error-50 border-error-500',
        status == 'default' && 'bg-blue-50 border-blue-500',
        status == 'success' && 'bg-success-50 border-success-500',
        containerClassName
      )}
    >
      <div className="flex items-start justify-start gap-4">
        {status !== 'success' ? (
          <ExclamationCircleIcon
            className={cn(
              'w-5 h-5 flex-none',
              status == 'alert' && 'text-amber-700',
              status == 'error' && 'text-error-600'
            )}
          />
        ) : (
          <CheckCircleIcon
            className={cn(
              'icon-xs flex-none mt-[2px]',
              status == 'success' && 'text-success-600',
              status == 'error' && 'text-error-600'
            )}
          />
        )}
        <div
          className={cn(
            status == 'alert' && ' text-amber-700',
            status == 'success' && 'text-success-600',
            status == 'error' && 'text-error-600'
          )}
        >
          <p className="font-bold mb-1">{title}</p>
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}
