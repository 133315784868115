import RangeSlider from './rangeSlider';
import RangeInput from './rangeInput';
import FilterWrapper from '../filterWrapper';

const PriceRange = () => {
  return (
    <FilterWrapper label="Price range">
      <RangeSlider attribute="price" />
      <RangeInput attribute="price" minLabel="Min price" maxLabel="Max price" />
    </FilterWrapper>
  );
};

export default PriceRange;
