import { cn } from '@/lib/utils/generalUtils';
import { PlusIcon, LockOpenIcon } from '@heroicons/react/outline';
import { MinusIcon } from '@heroicons/react/solid';

const PublicationCTAButton = ({
  canViewPricing,
  isWhitelabelOwner,
  isHidden,
  isRequireInquiry,
  handlePublicationSelect,
}) => {
  const renderContent = () => {
    if (!canViewPricing) {
      return (
        <>
          <LockOpenIcon className="block h-4 w-4" aria-hidden="true" />
          <span className="ml-2">Access</span>
        </>
      );
    }

    if (isWhitelabelOwner) {
      return isHidden ? (
        <>
          <PlusIcon className="block h-4 w-4 text-white" aria-hidden="true" />
          <span className="ml-2">Add</span>
        </>
      ) : (
        <>
          <MinusIcon className="block h-4 w-4 text-red-600" />
          <span className="ml-2 text-red-600">Remove</span>
        </>
      );
    }

    return isRequireInquiry ? (
      <span className="ml-2">Inquire</span>
    ) : (
      <>
        <PlusIcon className="block h-4 w-4 text-white" aria-hidden="true" />
        <span className="ml-2">Apply</span>
      </>
    );
  };

  return (
    <button className={cn('button !py-4 w-full md:w-[189px]')} onClick={handlePublicationSelect}>
      {renderContent()}
    </button>
  );
};

export default PublicationCTAButton;
