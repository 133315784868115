import Search from '@/components/icons/search';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import LoadingSpinner from '@/components/ui/loading-spinner';
import api from '@/lib/api';
import useModal from '@/lib/hooks/useModal';
import { useSavedList } from '@/lib/hooks/useSavedList';
import { usePublicationStore } from '@/lib/store/publication-list';
import { cn, sleep } from '@/lib/utils/generalUtils';
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/solid';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'sonner';
import CreatePublicationListModal from './create-publication-list-modal';
import { SAVED_LIST_QUERY_KEY } from './saved-list';

const SaveListMenu = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [openCreateList, setOpenCreateList] = useState(false);
  const [selectedListId, setSelectedListId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const { isOpen, setIsOpen } = useModal();

  const { selectedPublications, handleClearList } = usePublicationStore(
    (state) => state
  );

  const { data: savedLists } = useSavedList();

  const queryClient = useQueryClient();

  const handleSavedSelectedPublications = async (e, list) => {
    e.preventDefault();
    const publicationIds = list.publications?.map(
      (publication) => publication.id
    );

    if (selectedPublications.length === 0)
      return toast.info('No selected publications');

    const updatedPublicationIds = Array.from(
      new Set([...publicationIds, ...selectedPublications])
    ); // remove duplicates

    setIsSaving(true);
    setSelectedListId(list.id);

    const { error } = await api.publicationLists.update(
      list.id,
      updatedPublicationIds
    );

    if (error) {
      return toast.error(
        `Failed to saved selected publications to ${list.name}`
      );
    }

    queryClient.invalidateQueries({ queryKey: [SAVED_LIST_QUERY_KEY] });
    await sleep();
    setIsOpen(false);
    setIsSaving(false);
    setSelectedListId('');

    handleClearList();

    toast.success('List Saved!', {
      description: 'Your list was successfully added to Saved Lists',
      duration: 6000,
    });
  };

  const filteredSavedLists =
    searchValue === ''
      ? savedLists
      : savedLists?.filter((list) => {
          return list.name?.toLowerCase() === searchValue.toLowerCase();
        });

  return (
    <>
      <CreatePublicationListModal
        isOpen={openCreateList}
        setIsOpen={setOpenCreateList}
      />
      <DropdownMenu modal={false} open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <button
            className={cn(
              'font-semibold text-sm flex items-center gap-x-2 bg-transparent p-4',
              isOpen &&
                'border-primary-600 text-primary-600 hover:bg-transparent hover:text-primary-600/80'
            )}
            onPointerEnter={(e) => e.pointerType === 'mouse' && setIsOpen(true)}
          >
            <PlusIcon className="icon-sm" />
            SAVE LIST
            <ChevronDownIcon className="icon-sm" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          className="!min-w-[355px] p-2 bg-white"
          sideOffset={5}
        >
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 z-10">
              <Search className="icon-xs text-neutral-500" aria-hidden="true" />
            </div>
            <input
              id="search_list"
              name="search_list"
              placeholder="Search"
              className="input !pl-9"
              defaultValue={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </div>

          <DropdownMenuItem
            className="mt-2 p-0"
            onSelect={(e) => e.preventDefault()}
          >
            <button
              className="text-neutral-700 text-sm flex items-center rounded-none text-left justify-start px-2 py-1.5 hover:bg-primary-100 hover:text-primary-600 gap-x-2 w-full"
              onClick={() => setOpenCreateList(true)}
            >
              <PlusIcon className="w-5 h-5" />
              CREATE A NEW LIST
            </button>
          </DropdownMenuItem>
          <DropdownMenuSeparator />

          {searchValue && filteredSavedLists?.length === 0 && (
            <p className="text-neutral-600 py-5 text-center text-sm">
              No{' '}
              <span className="font-semibold">&quot;{searchValue}&quot;</span>{' '}
              saved list found
            </p>
          )}

          {filteredSavedLists?.map((list) => (
            <DropdownMenuItem
              key={list.id}
              className="hover:bg-primary-100 hover:text-primary-600 flex items-center justify-between"
              onSelect={(event) => handleSavedSelectedPublications(event, list)}
              disabled={isSaving}
            >
              {list?.name}
              {isSaving && selectedListId === list.id && (
                <LoadingSpinner className="text-gray-500" />
              )}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default SaveListMenu;
