import FiltersDialog from './filtersDialog';
import Search from './widgets/search';

const FiltersWithSearch = (props) => {
  return (
    <div {...props}>
      <Search className="min-w-[320px]" />
      <FiltersDialog />
    </div>
  );
};

export default FiltersWithSearch;
